<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>合作共赢</el-breadcrumb-item>
                <el-breadcrumb-item>分享机遇</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div>
                <vy-search-box v-model="searchVal" :btn-click="search" placeholder="搜索标题中存在字符"></vy-search-box>
            </div>
            <div>
                <VyAddBtn @click.native="openAdd" />
                <!-- <button @click="openAdd">添加</button> -->
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="url" align="center" label="链接"> </el-table-column>
                <el-table-column prop="sort" align="center" label="排序"> </el-table-column>
                <el-table-column prop="is_top" align="center" label="置顶">
                    <template slot-scope="{ row }">
                        <span>{{ row.is_top ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="btns">
                            <div class="_operate">
                                <button @click="openSet(row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <vy-pager
            :count="count"
            :page="page"
            :page-size="pageSize"
            @current-change="currentChange"
            @size-change="sizeChange"
        ></vy-pager>
        <!-- 添加或编辑 -->
        <el-dialog
            :title="form.id ? '编辑' : '添加'"
            :visible.sync="dialogShow"
            width="70%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="120px">
                <el-form-item label="标题">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input v-model.trim="form.intro"></el-input>
                </el-form-item>
                <el-form-item label="链接">
                    <el-input v-model.trim="form.url"></el-input>
                </el-form-item>
                <el-form-item label="关键词">
                    <el-input v-model.trim="form.keyword"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input-number v-model="form.sort" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="置顶">
                    <el-switch
                        class="sw"
                        v-model="form.is_top"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="是"
                        inactive-text="否"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="主图">
                    <upload-img :src="form.image" @removeSrc="form.image = ''" ref="image"></upload-img>
                </el-form-item>
                <el-form-item label="图标">
                    <upload-img :src="form.icon" @removeSrc="form.icon = ''" ref="icon"></upload-img>
                </el-form-item>
                <el-form-item label="自动生成二维码">
                    <el-switch
                        class="sw"
                        v-model="makeQR"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="是"
                        inactive-text="否"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="二维码图" v-show="!makeQR">
                    <upload-img :src="form.qrcode" @removeSrc="form.qrcode = ''" ref="qrcode"></upload-img>
                </el-form-item>
                <el-form-item label="详情">
                    <editorVue
                        :txt="form.details"
                        :getTxt="
                            txt => {
                                form.details = txt;
                            }
                        "
                        :height="500"
                    ></editorVue>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
import uploadImg from "../../../customs/uploadImg.vue";
import editorVue from "../../../customs/editor.vue";
import VyPager from "@/components/customs/VyPager.vue";
import VySearchBox from "@/components/customs/VySearchBox.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    components: { uploadImg, editorVue, VyPager, VySearchBox, VyAddBtn },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
            makeQR: false,
            page: 1,
            pageSize: 10,
            count: 0,
            searchVal: "",
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let { page, searchVal, pageSize } = this;
            let {
                data: { data, count },
            } = await axios.get("/api/partner/share", { params: { page, searchVal, pageSize } });
            this.count = count;
            return data;
        },
        openSet(row) {
            this.makeQR = false;
            this.form = { ...row };
            this.dialogShow = true;
        },
        openAdd() {
            this.makeQR = false;
            this.form = {
                title: "",
                url: "",
                intro: "",
                icon: "",
                image: "",
                qrcode: "",
                status: 1,
                sort: 1,
                is_top: 0,
                // 1分享机遇 2合作伙伴
                type: 1,
                details: "",
            };
            this.dialogShow = true;
        },
        async yes() {
            let { form } = this;
            form.image = this.$refs.image.url;
            form.icon = this.$refs.icon.url;
            form.qrcode = this.$refs.qrcode.url;
            if (this.makeQR) {
                let { data: qrcode } = await axios.post(
                    "/api/project/qrcode",
                    QueryString.stringify({
                        url: this.form.url,
                    })
                );
                this.form.qrcode = axios.defaults.baseURL + qrcode;
            }
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/partner/update", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/partner/add", QueryString.stringify(form));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
                return;
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => true);
            if (!s) return;
            let { data } = await axios.delete("/api/partner/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
        // 搜索
        async search() {
            this.page = 1;
            this.tableData = await this.getData();
        },
        // 当前页变化时
        async currentChange(currentPage) {
            this.page = currentPage;
            this.tableData = await this.getData();
        },
        async sizeChange(currentPageSize) {
            this.pageSize = currentPageSize;
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        display: flex;
        align-items: center;
        margin-top: 20px;
        & > div {
            flex: 1;
            text-align: right;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
